.sidebar-container {
  background-color: $background-navbar;
  height: 100vh;
  width: 20vw;
}

.sidebar-item {
  border: solid;
  border-width: 2px 0 0px 0;
  border-color: $border-gray;
  padding-top: 24px;
  padding-bottom: 24px;
}

.disabled {
  color: #000 !important;
  background-color: $background-disabled;
}
