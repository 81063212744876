* {
  // font-family: "Poppins", sans-serif;
  margin: 0px;
}

html,
body {
  height: 100vh;
  // width: 100vw;
  background-color: "white";
  color: $text;
  min-width: 400px;
  font-family: "Poppins", sans-serif;
}

h2 {
  font-weight: bold;
  font-size: xx-large;
}

h3 {
  font-weight: bold;
  font-size: x-large;
}

h4 {
  font-weight: 400;
  font-size: large;
}

h5 {
  font-weight: 400;
  font-size: medium;
}

.pressable {
  color: $primary;
  text-decoration: none;
  width: 100%;
  &:hover {
    background-color: $background-navbar;
    color: $secondary;
  }
}

abbr[title] {
  text-decoration-line: none;
  color: $secondary;
}

main {
  // width: 100%;
}

.holder {
  background-color: $sand;
  height: 100vh;
  width: 100vw;
}

.app-container {
  min-height: 90vh;
  // height: 100%;
  max-width: 100vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: $background-grey;
}

.mh-4 {
  margin-left: 4px;
  margin-right: 4px;
}
.mh-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.mh-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mh-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.mv-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mt-0 {
  margin-top: 0px;
}
.mt-1 {
  margin-top: 5px;
}
.mt-8 {
  margin-top: 8px;
}

.mt-5 {
  margin-top: 20px;
}

.m-0 {
  margin: 0;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 12px;
}

.mb-5 {
  margin-bottom: 16px;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-2 {
  margin-left: 8px !important;
}
.mr-2 {
  margin-right: 8px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.pointer {
  cursor: pointer;
}

.container-information {
  width: 65%;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.05);
  padding: 24px;
  margin-top: 24px;
  border-radius: 16px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-self: center;
  background-color: white;
}

.center {
  align-self: center;
  justify-self: center;
}

.flex-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // background-color: red;
}

.flex-1 {
  flex: 1;
}

// .flip {
//   // perspective: 1000px; /* Remove this if you don't want the 3D effect */
// }

.flip:hover {
  transform: rotateY(360deg);
  transition: transform 0.8s;
}
