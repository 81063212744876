.faq-container {
  width: 100%;
}

.faq-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.faq-posts {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.faq-alert {
  color: $secondary;
  align-self: center;
}

.faq-categories {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.faq-categories-length {
  margin: 8px 8px 8px 8px;
  padding: 8px 8px 8px 8px;
}
