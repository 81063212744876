$secondary: #f0533b;
$primary: #0e747c;
// $secondary: #0e747c;
$tertiary: #fcb944;
$text: #091f26;

$blue-sky: #78a4d7;
$sand: #e0b895;

$background: #fbfaf6;
$background-grey: #f8f6f2;
$background-home: #81b9bf;
$background-blue: #e5f6fe;
$background-navbar: rgba(29, 36, 86, 0.04);
$map-cover: rgba(251, 250, 246, 0.8);

$border-gray: #e5e5e4;

$background-hover: #fbfaf6;
$background-disabled: #909092;
$disabled: #f2f2f2;
$disabled-text: #999999;
