.search {
  display: flex;
  flex-direction: column;
}
.search-header {
  flex-direction: column;
  display: flex;
  background-color: #f8e5ce;
  //   height: 20vh;
  align-items: center;
  justify-content: center;
}
.search-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.search-text {
  padding: 5px;
  align-self: center;
  justify-self: center;
  text-align: center;
  font-size: large;
}
.search-input {
  // color: $text;
  // border: 0px;
  // border-bottom: 1px solid;
  // font-size: 16px;
  // margin-bottom: 10px;
  // margin-top: 10px;
  // height: 20px;

  width: 100%;
  // text-align: center;
  &:focus {
    border-bottom: 1px solid $primary;
    outline: none;
  }
}

.search-travelsfounds {
  align-self: center;
  margin: 5px;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-travel-one {
  box-shadow: 0px 0px 5px 1px  rgba(0, 0, 0, 0.05);
  padding: 5px;
  border: thin solid $primary;
  background-color: $background;
  width: 70vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}
.search-travel-information {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.search-travel-information-title {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.search-container {
  background-color: $background;
}

.search-button {
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.search-buttons {
  flex: 1;
  justify-self: end;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.search-travel-form-steps {
  flex-direction: column;
  display: flex;
}

.search-travel-steps-input {
  flex-direction: column;
  // height: 500px;
}
