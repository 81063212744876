.user-screen-container {
  display: flex;
  flex-direction: column;
  background-color: $background-grey;
  align-items: center;

  flex: 1;
  width: 100%;
}

.onetravel {
  width: 80vh;
  padding: 10px;
}

.onetravel-expand {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.info-user-input {
  width: 100%;
  flex: 1;

  min-height: 52px;
  border: 1px solid $text !important;
  border-radius: 4px;
  background-color: white;
  color: $text;
  font-size:medium;
  margin-bottom: 10px;
  margin-top: 10px;
  // margin-left: 8px;

  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  //   height: 20px;
  //   width: 40%;
  text-align: center;
  vertical-align: text-bottom;
  justify-items: center;
  align-items: flex-start;
}
.info-user-one {
  width: 100%;
  flex: 1;

  min-height: 52px;
  // border: 1px solid $text !important;
  border-radius: 4px;
  background-color: white;
  color: $text;
  font-size: 22px;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 8px;

  padding-top: 4px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  //   height: 20px;
  //   width: 40%;
  text-align: center;
  vertical-align: text-bottom;
  justify-items: center;
  align-items: flex-start;
}

.user-photo-large {
  width: 80px;
  height: 80px;
  align-self: center;
  margin: 24px;
  border-radius: 60px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 1px  rgba(0, 0, 0, 0.2);
  // border: 1px solid $text;
}
.user-photo-medium {
  width: 60px;
  height: 60px;
  align-self: center;
  margin: 24px;
  border-radius: 60px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 1px  rgba(0, 0, 0, 0.2);
  // border: 1px solid $text;
}

.input-photo {
  align-self: center;
  margin: 16px;
  display: none;
}

label {
  align-self: center;
  font-size: large;
  
}

label h5 {
  padding: 40px;
  font-size: larger;
  color: $secondary;
  font-weight: bold !important;
  font-size: 28px;
  border: 1px solid $primary;

  cursor: pointer;
}

.user-photo-small {
  width: 50px;
  height: 50px;
  align-self: center;
  // margin: 24px;
  border-radius: 60px;
  overflow: hidden;
  // border: 1px solid $text;
  box-shadow: 0px 0px 5px 1px  rgba(0, 0, 0, 0.2);
}
