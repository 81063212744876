.chat-container {
  width: 100%;
  //   min-width: 1244px;
  flex: 1;
  display: flex;
  flex-direction: row;
  //   overflow-x: scroll;
  // max-height: 50vh;
}

.chat-conversations-container {
  flex: 1;
  //   background-color: $blue-sky;
  //   border-right: 0.25px solid grey;
  overflow-y: scroll;
  display: block;
  max-height: 90vh;
  min-width: 400px;
  //   flex-direction: column;
  //   align-items: flex-start;
}

.chat-one-conversation {
  height: 120px;
  //   background-color: aquamarine;
  width: 100%;
  border: 1px solid $background-navbar;
  color: $text;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  //   padding-right: 0;

  &:hover {
    background-color: $background-navbar;
  }
}
.chat-one-conversation-user-info {
  //   align-self: flex-start;
  //   justify-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //   margin-top: 16px;
  // bottom: 0px;
}

.chat-one-conversation-user-photo {
  //   align-self: flex-start;
  //   justify-self: flex-start;
  background-color: gainsboro;
  border-radius: 80px;
  height: 80px;
  width: 80px;
  margin: 16px;
  //   margin-top: 0px;
  // bottom: 0px;
}
.chat-one-conversation-travel-info {
  align-self: flex-end;
  justify-self: flex-end;
  //   margin-bottom: 16px;
  //   bottom: 0px;
}

.chat-one-conversation-number {
  background-color: $secondary;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 800px;
  color: white;
}
.chat-one-conversation-text {
  text-decoration: none !important;
  text-decoration-line: none !important;
  -webkit-any-link: {
    text-decoration: none;
  }
}

.chat-conversation-container {
  flex: 3;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  //   overflow-y: scroll;
}
.chat-conversation-expanded {
  flex: 3;
  //   max-height: 50vh;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.chat-conversation-header {
  width: 100%;
  flex: 1;
  //   background-color: $background-home;
  //   border-bottom: 1px solid $text;
  border-radius: 8px;
  padding: 16px;
  padding-left: 32px;
  max-height: 140px;
  padding-right: 32px;
  background-color: $background-navbar;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chat-conversation-header-travel-user {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.chat-conversation-logo {
  height: 100%;
  position: relative;
}
.chat-conversation-messages {
  //   min-height: 60vh;
  flex: 4;
  // background-color: cornflowerblue;
  //   border-left: 0.5px solid $tertiary;
  //   border-right: 0.5px solid $tertiary;
  width: 100%;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
  max-height: 55vh;
  // justify-content: flex-end;
  //   direction: ;
  flex-direction: column-reverse;
  //   align-items: flex-end;
  overflow-y: scroll;
  scrollbar-width: thin;
  :first-child {
    margin-top: auto !important;
  }
}
.chat-message-self {
  display: flex;
  flex-direction: row-reverse;
}

.chat-message-info-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.chat-message-self-content {
  min-height: 80px;
  //   width: 50%;
  margin: 16px;
  background-color: azure;
  border-radius: 16px;
  right: 0px;
  align-self: flex-end;
  justify-self: right;
  border-bottom-right-radius: 0;
  padding: 8px;
}
.chat-message-other {
  display: flex;
  flex-direction: row;
}
.chat-message-other-content {
  min-height: 80px;
  //   width: 50%;
  margin: 16px;
  background-color: azure;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  padding: 8px;
}

.chat-insert-message {
  flex: 1;
  //   background-color: darksalmon;
  display: flex;
  min-height: 15vh;
}

.chat-input-container {
  flex: 3;
  //   border-radius: 16px;
  width: 70%;
  //   border-top: 1px solid black;
  background-color: $background-navbar;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chat-input {
  padding-bottom: 80%;
  padding: 8px;
  width: 70%;
  height: 70%;
  vertical-align: top;
  //   background-color: $background;
  border-radius: 8px;
  //   align-items: flex-start;
  //   justify-content: flex-start;
  //   vertical-align: super;
  //   display: flex;
  //   flex-direction: column;
}

.chat-input-self {
  border: 0px;
  width: 100%;
  height: 100%;
  //   padding-bottom: 40px;
  padding: 8px;
  border-radius: 8px;
  resize: none;
}

.chat-button-container {
  flex: 1;
}

.chat-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
