.admin-main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: $background-navbar;
  margin: 0px;
}

.admin-container {
  flex: 1;
  flex-direction: column;
  display: flex;
  padding: $padding_default;
  max-width: 80vw;
}
