.center-modal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: $map-cover;
  position: absolute;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //   transition: 0.5s ease-in-out;
  // visibility: visible;
  //   text-align: center;
  //   vertical-align: middle;
}

.center-modal-open {
  transform: scale(1);

  // max-height: 100vh;
  // max-width: 100vw;
  transition: all 0.3s cubic-bezier(1, 0, 1, 0);
}

.center-modal-close {
  // visibility: hidden;
  transform: scale(0);
  // max-width: 0vw;
  // max-height: 0vh;
  transition: all 1s cubic-bezier(0, 1, 0, 1);
}

// .hidden {
//   display: none;
// }

// .off {
//   transform: scale(0.2);
//   visibility: hidden;
// }

.card-modal {
  min-width: 360px;
  // max-width: 40%;
  // max-height: 80%;

  display: flex;
  flex-direction: column;

  background-color: $background-grey;
  box-shadow: 0px 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  border: 1px solid $primary;

  padding: 12px;

  overflow-y: scroll;
}

.card-modal-open {
  // transform: scale(1);
  max-width: 40%;
  max-height: 80%;
  transition: all 0.2s cubic-bezier(1, 0, 1, 0);
}

.card-modal-close {
  // visibility: hidden;
  transform: scale(0);
  // min-width: 0px;
  // padding: 0;
  // max-width: 0vw;
  // max-height: 0vh;
  transition: all 1s cubic-bezier(0, 1, 0, 1);
}

.card-modal::-webkit-scrollbar {
  display: none;
}
.modal-close-button {
  min-height: 32px;
  width: 100%;
  //   background-color: aquamarine;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  //   align-items: flex-end;
}

.close-icon-modal {
  cursor: pointer;
  color: $primary;
}

.children-card-modal {
  flex: 4;
  //   height: 80%;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 16px;
  //   padding-top: 8px;
  //   background-color: antiquewhite;
}

.children-card-modal-open {
  transform: scale(1);
  transition: all 1s cubic-bezier(1, 0, 1, 0);
  // flex: 4;
  // //   height: 80%;
  // flex-direction: column;
  // display: flex;
  // align-items: center;
  // padding: 16px;
  // //   padding-top: 8px;
  // //   background-color: antiquewhite;
}

.children-card-modal-close {
  transform: scale(0);
  transition: all 1s cubic-bezier(1, 0, 1, 0);
  // flex: 4;
  // //   height: 80%;
  // flex-direction: column;
  // display: flex;
  // align-items: center;
  // padding: 16px;
  //   padding-top: 8px;
  //   background-color: antiquewhite;
}

.button-card-modal {
  flex: 1;
  width: 100%;
  //   height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  //   flex: 1;

  //   background-color: aquamarine;
}

.download-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text-card-modal {
  //   flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-bottom: 32px;
  //   margin-bottom: 32px;
  //   border-bottom: 1px solid $background-home;

  //   text-align: center;
  //   vertical-align: middle;
}
.division {
  width: 100%;
  height: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid $background-home;
}

.image-card-modal {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 2s;
  transition: all 0.2s cubic-bezier(1, 0, 1, 0);
}

.image-card-modal-home {
  padding: 6px;
  // padding-bottom: 0px;
  margin-bottom: 32px;
  width: 20vw;
  min-width: 212px;
  background-color: #f8f6f2;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 2s;
  transition: all 0.2s cubic-bezier(1, 0, 1, 0);
  border: 1px solid $background-home;
  // opacity: 0.95;
}

.image-card-modal:hover {
  // position: absolute;
  background-color: white;
  z-index: 10000;
  transform: scale(1.4);
  transition: all 0.2s cubic-bezier(1, 0, 1, 0);
}
// .image-card-modal:not:hover {
//   // position: absolute;
//   // background-color: white;
//   // z-index: 10000;
//   transform: scale(1);
//   transition: all 0.2s cubic-bezier(0, 1, 0, 1);
// }

.download-qr {
  width: 50%;
  min-width: 200px;
  min-height: 200px;
}

.download-qr-home {
  width: 100%;
  min-width: 200px;
  min-height: 200px;
}

.download-stores {
  //   max-width: 25%;
  height: 40px;
  //   border: 1px solid $background-home;
  //   border-radius: 8px;
  // min-width: 300px;
}
.download-google-stores {
  //   max-width: 25%;
  height: 50px;
  //   border: 1px solid $background-home;
  //   border-radius: 8px;
  // min-width: 300px;
}

.download-card-modal {
  //   flex: 1;
  width: 100%;
  //   flex-direction: column;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  //   background-color: aqua;
  align-items: center;

  //   margin-bottom: 16px;

  //   text-align: center;
  //   vertical-align: middle;
}
