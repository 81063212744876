.travel-summary-container {
  padding: 16px;
  height: 100%;
  margin: 24px;
  background-color: $background;
  border-radius: 8px;
  border: 1px solid $text;
  // top: -10;
  // position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.travel-summary-item {
  margin: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.travel-tabs {
  margin: 16px;
  border-color: $text;
  border: 0px;
}

.travel-tabs-item {
  color: $text !important;
}

.travel-tabs-link {
  color: $text !important;
  font-size: 18px;

  &:focus {
    border: 0px;
    border-color: $primary !important;
    border-bottom: 4px solid;
    border-top: 0px solid;
    border-left: 0px solid;
  }
}

.travel-results-container {
  background: white;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  // border: 0.1px solid $background-grey;
}

.travel-results {
  margin: 24px;
}

.travel-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;

  width: 80%;
}

.travel-informations {
  margin-bottom: 32px;
  margin-top: 32px;
  // background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.travel-information-right {
  text-align: end;
}

.travel-steps-container {
  margin: 56px;
  display: flex;
  flex-direction: column;
  width: 55%;
  justify-content: space-between;
}

.travel-passengers-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}
.travel-passenger-photo {
  flex: 1;
}
.travel-passenger-information {
  flex: 2;
}
.travel-passenger-status {
  flex: 1;
}
.travel-passenger-buttons {
}
.travel-passenger-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border: 0.25px solid $background-grey;
  padding: 8px;
  border-radius: 8px;
  // background-color: aqua;
  align-items: center;
}

.travel-conductors-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 55%;
  justify-content: space-between;
}

.travel-conductor-container {
  padding: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-radius: 8px;
  align-items: center;
}

.travel-passenger-container-waiting {
  border: 0.25px solid $secondary;
}

.travel-step-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  color: $text;
  text-decoration: none;

  &:hover {
    // background-color: $background-navbar;
    color: $secondary;
  }
}
