.auth__main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: $background-navbar;
  margin: 0px;
  height: 100vh;
  width: 100vw;
}

.auth__box-container {
  margin-top: 80px;
  width: 50%;
  background-color: $background;
  box-shadow: 0px 0px 5px 1.5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  flex-direction: column;
  display: flex;
  padding: 16px;
}

.auth__title {
  color: $text;
  align-items: center;
  justify-self: center;
  text-align: center;
}

.auth__form {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.auth__input {
  width: 100%;
  flex: 1;
  //   min-width: 265px;
  min-height: 40px;
  border: 1px solid $border-gray;
  border-radius: 2px;
  background-color: white;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 4px;
  margin-left: 4px;

  text-align: center;

  &:focus {
    // border-bottom: 1px solid $primary;
    outline: none;
  }

  &:focus {
    border-color: $primary;
  }

  &::-webkit-input-placeholder {
    font-size: medium;
    align-self: flex-start;
    justify-self: flex-start;
  }

  &:not([value=""]) {
    border-color: $primary;
  }
}

.auth__phone {
  border: 0px !important;

  &:focus{
    outline: none;
  }
  &:active{
    border: none !important;
  }
}
.auth-howto-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth-howto-btn {
  width: 100%;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.fb {
  background-color: #4c69ba !important ;
}

.google {
  background-color: white !important;
  color: $text !important;
  border: 1px solid $text !important;
}
