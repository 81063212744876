.add-travel {
  flex: 1;
  display: flex;
}
.add-travel-maps-container {
  flex: 10;
  width: 100vw;
  flex-direction: row;
  display: flex;

  // align-items: center;
  justify-content: space-between;
  // flex-direction: column;
  // width: 100vw;
  position: relative;
  // height: 100%;
  // background-color: red;
}

.add-travel-header {
  align-self: flex-start;
  justify-self: center;
  // max-width: 30%;
  height: auto;
  background-color: $map-cover;
  // flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 40px;
  z-index: 10;
  // min-height: 20vh;
}

// .add-travel-maps {
//   // align-items: center;
//   // justify-content: center;
//   // flex-direction: column;
//   // display: flex;
//   // height: 100%;
//   // background-color: red;
// }

.add-travel-map-self {
  flex: 3;
  // z-index: 1;
  position: absolute;
  // top: 0;
  // flex: 10;
  // width: 200px;
  // height: 200px;
  width: 100%;
  height: 100%;
  // position: absolute;
}

.add-travel-summary-maps {
  padding-bottom: 16px;
  font-size: large;
  height: 100%;
  // max-width: max-content;
  max-width: 30%;
  z-index: 4;
  background-color: $map-cover;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: end;
  // opacity: 0.5;
}

.add-travel-wrapper {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  // max-width: 100%;
  flex: 1;
}

.add-travel-form-steps {
  flex: 1;
  align-self: center;
  justify-self: center;
}
.add-travel-buttons {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
// .add-travel-summary {
//   background-color: red;
// }

.add-travel-form {
  margin-left: 16px;
  margin-right: 16px;
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.pac-item {
  padding: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  border-top: 0.5px solid #e6e6e6;
  text-align: center;
}
.pac-item-query {
  font-size: 18px;
}

.pac-matched {
  font-weight: normal;
}

.pac-icon {
  display: none;
}

.pac-container {
  border-radius: 6px;
  width: 300px !important;
}

.pac-container::after {
  display: none;
}

.map-screen-container {
  background-color: red;
  position: absolute;
  height: 100%;
  flex: 1;
  width: 100%;

}
