.btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: aliceblue;
  font-size: 18px;
  padding: 8px 16px 8px 16px;
  margin: 5px;

  &:focus {
    outline: none;
  }

  &:hover {
    $color: $primary;
  }
}

.btn-primary {
  font-family: "Inter", sans-serif;
  background-color: $primary;
  border-radius: 16px;
  // max-width: 60%;
  max-height: 48px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: lighten($color: $primary, $amount: 1);
  }

  &:hover {
  }
}

.center-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hidden {
  visibility: hidden;
}

.btn-block {
  width: 50%;
}
.navbar-link {
  text-decoration: none;
  color: grey;
}

.btn-normal {
  background-color: $primary !important;
  border-radius: 8px;
  // max-width: 60%;
  max-height: 48px;
  width: fit-content;
  align-self: center;

  &:disabled {
    background-color: lighten($color: $primary, $amount: 1);
  }

  &:hover {
  }
}

.btn-accept {
  // width: 100px;
  vertical-align: center;
  background-color: $primary;
}
.btn-decline {
  // width: 100px;
  // vertical-align: center;
  background-color: white;
  border: 1px solid $primary;
}

.btn-icon {
  margin: 4px;
  bottom: -10px;
}
