.notifications-container {
  margin-top: 8px;
  background: white;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  border: 0.1px solid $background-grey;
}

.notification-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.25px solid $background-grey;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  text-decoration-line: none;
  color: $text;
  

  &:hover {
    background-color: $background-hover;
    color: $secondary;
  }
}

.notification-icon {
  color: $primary;
}

.notification-icon-navbar {
  position: relative;
  display: inline;
}

.notification-icon-navbar-number {
  position: absolute;
  top: -5px;
  right: 0;
  background-color: $secondary;
  font-size: 11px;
  color: white;
  display: inline;
  padding: 3px 5px;
  border-radius: 25px;
}
