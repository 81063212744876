.input-container {
  margin: 8px 16px 8px 16px;
  flex: 1;
  width: 100%;
}

.input {
  border: 1px solid $text !important;
  border-radius: 4px;
  min-height: 52px;
  width: 100%;

  background-color: white;
  color: $text;
  margin: 8px 0 0 0;
  padding: 8px 8px 8px 8px;
  line-height: 24px;
  text-align: start;
  vertical-align: text-top;
}

.input-select {
  flex: 1;
  border: 1px solid $text !important;
  border-radius: 4px;
  margin: 44px 16px 0px 16px;
  height: 100%;
  background-color: white;
  color: $text;
}

.input-disabled {
  background-color: $disabled;
  color: $disabled-text;
}
