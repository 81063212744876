.reservations-one-reservation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //   margin-left: 16px;
  //   margin-right: 16px;
  width: 96%;
  border: 1px solid $primary;
  align-items: center;

  padding: 10px;
  border-radius: 16px;
  margin-bottom: 24px;

  &:hover {
    cursor: pointer;
    background-color: $background-grey;
  }
}

.reservations-one-reservation-title {
  flex: 1;
}
.reservations-one-reservation-hour {
  flex: 1;
}
.reservations-one-reservation-status {
  flex: 1;
}

.reservations-one-reservation-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
}
