.faq-container {
  z-index: 10;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.faq-question-container {
  flex-direction: column;
  z-index: 5;
  width: 50vw;
  box-shadow: 0px 0px 24px 0px #00000025;
  margin-top: 16px;
  margin: 16px;
  background-color: $background-hover;
  border-radius: 16px;
  @media screen and (max-width: 1000px) {
    width: 80vw;
  }
}

.faq-question {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 24px;
  padding-bottom: 24px;

  display: flex;
  z-index: 10;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}

.faq-title {
  flex: 1;
  font-size: large;
  font-weight: 600;
}

.faq-icon {
  display: flex;
  cursor: pointer;
  color: $primary;
  // background-color: $secondary;
  align-self: center;
  justify-content: center;
}

.faq-text-container-open {
  height: auto;
  overflow: hidden;
  max-height: 1000px;
  // display: none;
  // padding: 0;
  transition: all 0.2s cubic-bezier(1, 0, 1, 0);
}

.faq-text-container-close {
  max-height: 0;
  overflow: hidden;
  // display: none;
  // padding: 0;
  transition: all 1s cubic-bezier(0, 1, 0, 1);
}

.faq-text {
  font-size: 18px;
  text-align: center;
  font-size: medium;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  z-index: 5;

  padding-left: 40px;

  padding-right: 40px;
  padding-top: 8px;
  padding-bottom: 16px;

  border-radius: 4px;
}
