.navbar-container {
  background-color: $background;
  font-size: $navbar-font-size;
  font-family: "Poppins", sans-serif;
  // flex-direction: row;
  // display: flex;
  padding-right: 64px;
  padding-left: 64px;
  max-width: 100vw;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  min-height: 80px;
  @media screen and (max-width: 1200px) {
    min-height: 160px;
  }
}

.navbar-brand {
  flex: 1;
}
.navbar-expanded {
  justify-content: end;
  flex: 3;
}

.navbar-central {
  // flex: 3;
  position: absolute;
  right: 45vw;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    position: relative;
    right: 0;
    align-self: flex-start;
    justify-self: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.navbar-toggle {
  position: absolute;
  vertical-align: center;
  justify-self: flex-end;
  right: 40px;
}

.navbar-link {
  text-decoration: none;
  color: $primary;
  font-weight: bold;
  font-size: large;
}

.nav-link {
  font-weight: bold;
  font-size: large;
  color: $primary !important;
}
// .navbar-link {
//   text-decoration: none;
//   color: $primary;
// }

.navbar-link-connect {
  text-decoration: none;
  color: $primary;
  // background-color: bisque;
  color: $secondary;

  padding: 4px;
  align-self: center;
  justify-self: center;
  border-radius: 8px;
  // width: max-content;
}

.navbar-dropdown {
  padding: 0 !important;
  border-radius: 5px;
  color: $primary !important;
  font-size: x-large;
  top: 4px;
}
.navbar-dropdown-item {
  font-size: 18px;
  text-align: center;
  padding: 10px;
  border-bottom: 0.5px solid grey;
}

.navbar-dropdown-last-item {
  text-align: center;
  padding: 10px;
}

.dropdown-menu {
  padding: 0;
}

.notification-item {
  min-width: 40px;
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  padding: 8px;
}

.navbar-user-info {
  display: flex;
  flex-direction: row;
}

.navbar-info {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
  background-color: #ffeccc;
  z-index: 1000;
}

.navbar-warning {
  background-color: #fdd;

  &:hover {
    cursor: pointer;
  }
}

.navbar-icon {
  margin-right: 4px;
}
