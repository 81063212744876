.home-header {
  padding: 80px;
  padding-top: 40px;
  display: flex;
  max-width: 100vw;
  flex: 1;
  flex-direction: column;
  background-color: $sand;
  align-items: center;
  justify-content: flex-start;
  background-image: url(../../assets/images/bariloche.jpg);
  // background-image: url(../../assets/images/route-bg.jpg);
  background-size: cover;
  // backgrou
  background-repeat: no-repeat;
  background-position: center;
  // background-position-y: -140px;
  min-height: 100vh;

  // @media screen and (max-width: 1800px) {
  //   background-position-y: -240px;
  // }

  // @media screen and (max-width: 1600px) {
  //   background-position-y: -160px;
  // }
  // @media screen and (max-width: 1400px) {
  //   background-position-y: -40px;
  // }
  // @media screen and (max-width: 1300px) {
  //   background-position-y: -20px;
  // }

  // @media screen and (max-width: 1800px) {
  //   background-position-y: 0px;
  //   // background-image: none
  //   min-height: 60vh;
  // }

  // @media screen and (max-width: 1600px) {
  //   background-position-y: 0px;
  //   // background-image: none;
  //   min-height: 60vh;
  // }

  // @media screen and (max-width: 1400px) {
  //   background-position-y: 0px;
  //   // background-image: none;
  //   min-height: 60vh;
  // }

  // @media screen and (max-width: 1200px) {
  //   background-position-y: -20px;
  //   // background-image: none;
  //   min-height: 60vh;
  // }

  // @media screen and (max-height: 700px) {
  //   background-position-y: -60px;
  // }

  // @media screen and (max-height: 700px) and (min-width: 1200px) {
  //   // background-position-y: -200px;
  // }

  // // overflow-y: hidden;

  // height: 50vh;

  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1200px) {
  .home-header {
    align-items: center;
    padding: 20px;
    background-color: $blue-sky;
  }
}

.home-content {
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.flex-direction-column {
  flex-direction: column;
}
.home-column {
  // position: absolute;
  // flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  // background-color: aquamarine;
  min-height: 100%;
  padding: 40px;
  max-width: 50vw;
  @media screen and (max-width: 1000px) {
    max-width: 100vw;
  }
}

.home-screenshot {
  width: 18vw;
  min-width: 300px;
  padding: 0;
}

.home-screenshot:hover {
  transform: rotateY(360deg);
  transition: transform 0.8s;
}

.home-first-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  // height: 70vh;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.home-second-row {
  // background-color: $background-home;
  padding: 160px;
  padding-top: 0px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 80px;
  }
}

.home-third-row {
  background-color: $background-blue;
  padding: 160px;
  background-image: url(../../assets/images/route-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 80px;
  text-align: center;
  flex-direction: column;
  // text-justify: newspaper;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    padding: 80px;
  }
}

.home-row {
  display: flex;
  flex-direction: row;
  // background-color: red;
  width: 70%;
  // padding: 80px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    width: 90vw;
    padding: 0px;
  }
}

.route-bg {
  background-image: url(../../assets/images/jujuy.jpg);
  // background-image: url(../../assets/images/Homebg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

// .home-image {
//   position: absolute;
//   right: 0px;
//   z-index: 1;
//   max-height: 600px;
//   max-width: 600px;
//   // opacity: 50%;
//   // max-height: 30vh;
// }
.home-image {
  position: absolute;
  max-width: 100vw;
  max-height: 110%;
  right: 0px;
  top: -200px;

  @media screen and (max-width: 1600px) {
    top: -80px;
  }
  @media screen and (max-width: 1400px) {
    top: -40px;
  }
  @media screen and (max-width: 1300px) {
    top: -0px;
  }

  @media screen and (max-width: 1200px) {
    top: 85px;
  }

  top: 0;

  // max-height: 600px;
  // max-width: 600px;
  // opacity: 50%;
  // max-height: 30vh;
}

.home-title {
  font-weight: bold;
  z-index: 2;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  margin-bottom: 40px;
  font-size: xx-large;
  @media screen and (min-width: 1100px) {
    font-size: 48px;
  }
  color: white;
  text-align: center;
}
.home-input {
  //   color: $text;
  //   border: 0px;
  //   border-bottom: 1px solid;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
  //   height: 20px;
  //   width: 40%;
  text-align: center;
  //   &:focus {
  //     border-bottom: 1px solid $primary;
  //     outline: none;
  //   }
}

.home-input-container {
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
}

.home-input-title {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}

.home-input-subtitle {
  font-size: 18px;
  text-align: center;
}

.home-preApp {
  background-color: $background;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 4px;
  margin-left: 64px;
  margin-right: 64px;
  display: flex;
  z-index: 5;
  flex-direction: column;
  box-shadow: 0px 0px 16px 0px #00000055;
  align-items: center;
}
.home-info {
  font-family: "Poppins", sans-serif;
  min-width: none;
  background-color: $background;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 4px;
  margin-left: 64px;
  margin-right: 64px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 375px;

  //max-height: 553px;
}
.home-info-title {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}

.home-info-text {
  font-size: 18px;
  text-align: center;
  font-weight: normal;
}

.home-input {
  width: 100%;
  flex: 1;
  min-width: 265px;
  min-height: 52px;
  border: 1px solid $text !important;
  border-radius: 4px;
  background-color: white;
  color: $text;
}

.react-date-picker__wrapper {
  color: $background-home !important;
  border: 0px;
  min-width: 277px;
}

.home-search {
  font-family: "Poppins", sans-serif;
  background-color: $background;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 4px;
  margin-left: 64px;
  margin-right: 64px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-search-horizontal {
  flex-direction: row;
}

@media screen and (max-width: 1100px) {
  .home-image {
    // right: 0%;
    left: 0px;
    min-width: 400px;
  }

  .home-title {
    line-height: 64px;
    margin-bottom: 40px;
  }

  .home-search {
    margin-left: 16px;
    margin-right: 16px;
    width: 100vw;
    min-width: 320px;
  }
}

.home-input::-webkit-input-placeholder {
  color: $background-home;
}

.home-search-button {
  padding-left: 48px;
  padding-right: 48px;
  border-radius: 8px;
  margin-top: 32px;
  align-self: center;
  justify-self: flex-end;
  vertical-align: bottom;
}

.home-waiting {
  flex: 1;
  height: 100vh;
  width: 100vw;
  background-color: $background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.updating-waiting {
  position: absolute;
  top: 0;
  left: 0;
  flex: 1;
  opacity: 40%;
  height: 100vh;
  width: 100vw;
  background-color: $background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.waiting-image {
  animation: rotation 1.5s infinite ease-in-out;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.card {
  background-color: $background-hover;
  padding: 32px 40px;
  border-radius: 16;
  border-radius: 16px;
  min-width: 300px;
  // flex: 1;

  box-shadow: 0px 0px 24px 0px #00000025;
}

.input-email {
  width: 90%;
  margin: 40px 40px;
  background-color: $background-hover;
}
