.payTravel-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  // max-width: 600px;
  align-items: center;
  justify-content: flex-start ;
  background-color: $background-grey;
  padding: 8px;
}

.payTravel-container-information{
  width: 65%;

  padding: 24px;
  margin-top: 24px;
  border-radius: 16px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-self:center ;
  border: 0.25px solid $text;
  background-color: $background;
  align-items: center;
  justify-content: space-around;
}
.payTravel-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  width: 75vw;
}
.payTravel-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}
.payTravel-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  margin-top: 50px;
  // width: 30%;
}

.payTravel-date {
  width: 20%;
}

.payTravel-cvc {
  width: 20%;
}

.payTravel-input {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  // color: $text;
  // border: 0px;
  // border-bottom: 1px solid;
  // font-size: 16px;
  // margin-bottom: 10px;
  // margin-top: 10px;
  // height: 20px;

  // text-align: center;
  &:focus {
    border-bottom: 1px solid $primary;
    outline: none;
  }
}

.payTravel-button {
  position: absolute;
  bottom: 5vh;
   width: 20vw;
  max-width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 20px;
}

.payTravel-button-pay {
  background-color: $secondary!important;
  position: absolute;
  bottom: 5vh;
   width: 20vw;
  max-width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 14px;
}
.payTravel-button-icon {
  bottom: 10;
}

.payTravel-card {
  margin-top: 20px;
}

.payTravel-info-text{
  font-size: small;
  color: gray;
  margin-top: 20px;
}
