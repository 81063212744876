.footer {
  min-height: 120px;
  background-color: $primary;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  z-index: 100000000;

  justify-content: flex-start;
}

.footer-links {
  flex: 4;
  // padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 80px;
  padding-right: 80px;

  display: flex;
  flex-direction: row;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
  align-items: center;
  justify-content: center;
}

.footer-column {
  // background-color: bisque;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 40px;
  min-width: 10vw;

  margin: 16px;
  padding-left: 72px;
  @media screen and (max-width: 700px) {
    margin: 0;
    padding: 16px;
    align-self: flex-start;
    padding: 16px;
  }
}

.footer-link {
  font-size: small;
  font-weight: 500;
  color: white;
  margin-bottom: 8px;
  text-decoration: none;
  cursor: pointer;
}

.footer-informations {
  padding-top: 4px;
  padding-bottom: 4px;
  z-index: 10;
  background-color: #1d2456;
  padding-left: 80px;
  padding-right: 80px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer-text-info {
  font-size: x-small;
  font-weight: 500;
  font-family: "Martel Sans", sans-serif;
  cursor: pointer;
  color: white;
}
